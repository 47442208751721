import React from "react";
import { withRouter } from  "../components/withRouter";
import axios from 'axios';
import DataTable from 'react-data-table-component';
import InnerHeader from "../components/InnerHeader";
import SidebarOne from "../components/sidebar1";

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      search: '',
      prescription: 'no',
      suc_cls: '',
      errors: {},
    };
  }

  handleDivClick = (id) => {
    localStorage.setItem("user_id", id);
    this.props.navigate('/Viewusers'); // Fixed method call
  };

  componentDidMount() {
    this.callgetcustomersapi();
  }

  onChange_search = (e) => {
    this.setState({
      search: e.target.value
    }, () => this.callgetcustomersapi());
  }

  // callgetcustomersapi = () => {
  //   let url = this.state.api_url + '/admingetCustomers';
  //   if (this.state.search !== '') {
  //     url = this.state.api_url + '/admingetCustomers?search=' + this.state.search;
  //   }
  //   axios.get(url)
  //     .then(res => {
  //       const data = res.data;
  //       this.setState({
  //         data: data.customers,
  //       });
  //     });
  // }

  callgetcustomersapi = () => {
    const url = `${this.state.api_url}/admingetCustomers`;

    axios.post(url, { search: this.state.search })
      .then(res => {
        const data = res.data;
        this.setState({
          data: data.customers,
        });
      })
      .catch(error => {
        console.error("There was an error fetching the customers!", error);
      });
  }


  render() {
    const columns = [
      {
        name: 'Name',
        selector: row => row.name,
      },
      {
        name: 'Last Activity',
        selector: row => row.last_activity,
      },
      {
        name: 'Status',
        selector: row => 'active',
      },
      {
        name: 'Verified',
        selector: row => 'yes',
      }
    ];

    return (
      <div>
        <div className="productsa">
          <SidebarOne />
            <InnerHeader />
            <hr></hr>
          <p className="user_m">User Management</p>
          <div className="frame-3 row">
            <div className="frame-5" style={{ left: "5px" }}>
              <input type="text" placeholder="Search..." className="searchtxt" value={this.state.search} onChange={this.onChange_search} />
            </div>
          </div>
          <div className="overlap-6">
            <div className={this.state.suc_cls}>{this.state.errors.message}</div>
            <div className="group-4">
              <DataTable
                columns={columns}
                data={this.state.data}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Customers);