import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function AddImages(props) {
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');
  const product_id = localStorage.getItem('product_id');

  const validateInputs = () => {
    const errors = {};
    if (files.length === 0) {
      errors.files = "At least one image is required";
    }
    return errors;
  };

  const addproductdetails = () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const formData = new FormData();
    formData.append("product_id", product_id);
    files.forEach(file => {
      formData.append("images", file);
    });

    axios.post(`${api_url}/addproductimages`, formData)
      .then((res) => {
        alert(res.data.message);
        fetchProductImages();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error adding images:", error);
      });
  };

  const fetchProductImages = () => {
    axios.get(`${api_url}/getproductimages/${product_id}`)
      .then((res) => {
        setImages(res.data);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  };

  const removeImage = (imageId) => {
    axios.delete(`${api_url}/removeproductimage/${imageId}`)
      .then((res) => {
        alert(res.data.message);
        fetchProductImages();
      })
      .catch((error) => {
        console.error("Error removing image:", error);
      });
  };

  useEffect(() => {
    fetchProductImages();
  }, []); // Run once on mount

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Product Images</p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>
        <hr></hr>
        <div className="product_detail">
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Product Images</p>
              <button className="itme_img11" onClick={addproductdetails}>Save</button>
            </div>
            <input type="file" multiple onChange={handleFileChange} />
            {errors.files && <Alert severity="error">{errors.files}</Alert>}
          </div>
          <div className="image-preview">
            {images.map((image, index) => (
              <div key={index} className="image-container">
                <img src={image.images} alt={`product-${index}`} className="preview-image" />
                <button className="remove-image" onClick={() => removeImage(image._id)}>X</button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(AddImages);
