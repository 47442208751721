import React from 'react';
import { NavLink } from 'react-router-dom';
import {withRouter} from "../components/withRouter";
import axios from 'axios';

class InnerHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: {},
      data1: {},
      data2: {},
      data3: {}
    };
  }

  componentDidMount() {
    const { api_url } = this.state;

    axios.get(`${api_url}/getCounts`).then((res) => {
      this.setState({ data: res.data.counts });
    });

    axios.get(`${api_url}/getCountsuser`).then((res) => {
      this.setState({ data1: res.data.counts1 });
    });

    axios.get(`${api_url}/getCountsStores`).then((res) => {
      this.setState({ data2: res.data.counts1 });
    });

    axios.get(`${api_url}/getCountsAgents`).then((res) => {
      this.setState({ data3: res.data.counts1 });
    });
  }

  handleLogout = () => {
    // Clear user data from localStorage
    localStorage.removeItem('store_id');
    localStorage.removeItem('api_url');
    // Redirect to the login page or home page
    this.props.navigate('/login'); // Assuming '/login' is the login route
  }

  render() {
    const store_id = localStorage.getItem('store_id');
    const { data, data1, data2, data3 } = this.state;

    return (
      <div className="innerHeader">
        {store_id === "null" || !store_id ? (
          <div className="group-ih-2 row">
            <div className="col-md-2" style={{ left: "124px" }}>
              <NavLink to="/Dashboard" activeClassName="active">
                <img
                  className="img-3"
                  alt="Ic poll"
                  src={require('../assets/img/ic-poll-48px.svg').default}
                />
                <div className="in-header">Dashboard</div>
              </NavLink>
            </div>

            <div className="col-md-2" style={{ marginLeft: "88px" }}>
              <NavLink to="/Customers" activeClassName="active">
                <img
                  className="glyph-mail-trash"
                  src="/assets/img/glyph-mail-trash-2.png"
                  alt="Glyph mail trash"
                />
                <div className="in-header">User Management</div>
                <div className="in-wrapper">
                  <div className="button-text">{data1.customerc}</div>
                </div>
              </NavLink>
            </div>

            <div className="col-md-2" style={{ marginLeft: "22px" }}>
              <NavLink to="/Stores" activeClassName="active">
                <img
                  className="img-3"
                  alt="Ic store"
                  src={require('../assets/img/ic-store-48px.svg').default} 
                />
                <div className="in-header">Stores</div>
                <div className="in-element-status-indicators-2" style={{ left: "107px" }}>
                  <div className="button-text">{data2.storesc}</div>
                </div>
              </NavLink>
            </div>

           

            <div className="col-md-2" style={{ left: "-9%" }}>
              <NavLink to="/Products" activeClassName="active">
                <img
                  className="img-3"
                  alt="Glyph mail trash"
                  src="/assets/img/glyph-mail-trash.png"
                />
                <div className="in-header">Products</div>
                <div className="in-element-status-indicators-2" style={{ left: "125px" }}>
                  <div className="button-text">{data.productc}</div>
                </div>
              </NavLink>
            </div>

          <div className="col-md-2" style={{ left: "87%" }}>
            <button className='btn btn-primary'style={{marginTop:'15px',borderRadius:'5px'}} onClick={this.handleLogout}>Logout</button>
          </div>
 
          </div>
        ) : (
          <div className="col-md-2" style={{ left: "81%" }}>
            <button className='btn btn-primary'style={{marginTop:'15px',borderRadius:'5px'}} onClick={this.handleLogout}>Logout</button>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(InnerHeader);
